<template>
  <div>
    Du loggas ut...
  </div>
</template>

<script>
// import { useStore } from 'vuex';
// import { useRouter } from "vue-router"

export default {
  setup() {
    // const store = useStore();
    
    // const router = useRouter();
    
    this.$store.dispatch('logout')
      .then(() => {
        this.$router.replace({ name: 'login' });
      });
  },
}
</script>
